<template>
  <div class="row">
    <div class="col-12">
        <p>{{ user.fullname }}</p>
        <small>{{ user.telephone }}</small>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        object: {type: Object, required: true}
    },
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            users: 'auth/users'
        }),
        user(){
            return this.users.find(item => item.uid === this.object.user)
        }
    }

}
</script>